import { useApplicationContext } from "context/Application/Application";
import { SecuritiesRegisterContextProvider } from "./Context";
import { Layout } from "./Layout";

export const SecuritiesRegister = () => {
  const { viewConfiguration } = useApplicationContext();
  const system = !!viewConfiguration["Smart Instruments"]?.ReadUNSCOPED;

  return (
    <SecuritiesRegisterContextProvider system={system}>
      <Layout />
    </SecuritiesRegisterContextProvider>
  );
};
