/**
 * @fileoverview gRPC-Web generated client stub for financialPaymentView
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.28.0
// source: views/financialPaymentView/modelUpdater.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var security_context_pb = require('../../security/context_pb.js')

var financial_payment_pb = require('../../financial/payment_pb.js')
const proto = {};
proto.financialPaymentView = require('./modelUpdater_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.financialPaymentView.ModelUpdaterClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.financialPaymentView.ModelUpdaterPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.financialPaymentView.UpdateForPaymentIDRequest,
 *   !proto.financialPaymentView.UpdateForPaymentIDResponse>}
 */
const methodDescriptor_ModelUpdater_UpdateForPaymentID = new grpc.web.MethodDescriptor(
  '/financialPaymentView.ModelUpdater/UpdateForPaymentID',
  grpc.web.MethodType.UNARY,
  proto.financialPaymentView.UpdateForPaymentIDRequest,
  proto.financialPaymentView.UpdateForPaymentIDResponse,
  /**
   * @param {!proto.financialPaymentView.UpdateForPaymentIDRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.financialPaymentView.UpdateForPaymentIDResponse.deserializeBinary
);


/**
 * @param {!proto.financialPaymentView.UpdateForPaymentIDRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.financialPaymentView.UpdateForPaymentIDResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.financialPaymentView.UpdateForPaymentIDResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.financialPaymentView.ModelUpdaterClient.prototype.updateForPaymentID =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/financialPaymentView.ModelUpdater/UpdateForPaymentID',
      request,
      metadata || {},
      methodDescriptor_ModelUpdater_UpdateForPaymentID,
      callback);
};


/**
 * @param {!proto.financialPaymentView.UpdateForPaymentIDRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.financialPaymentView.UpdateForPaymentIDResponse>}
 *     Promise that resolves to the response
 */
proto.financialPaymentView.ModelUpdaterPromiseClient.prototype.updateForPaymentID =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/financialPaymentView.ModelUpdater/UpdateForPaymentID',
      request,
      metadata || {},
      methodDescriptor_ModelUpdater_UpdateForPaymentID);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.financialPaymentView.UpdateForSmartInstrumentIDRequest,
 *   !proto.financialPaymentView.UpdateForSmartInstrumentIDResponse>}
 */
const methodDescriptor_ModelUpdater_UpdateForSmartInstrumentID = new grpc.web.MethodDescriptor(
  '/financialPaymentView.ModelUpdater/UpdateForSmartInstrumentID',
  grpc.web.MethodType.UNARY,
  proto.financialPaymentView.UpdateForSmartInstrumentIDRequest,
  proto.financialPaymentView.UpdateForSmartInstrumentIDResponse,
  /**
   * @param {!proto.financialPaymentView.UpdateForSmartInstrumentIDRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.financialPaymentView.UpdateForSmartInstrumentIDResponse.deserializeBinary
);


/**
 * @param {!proto.financialPaymentView.UpdateForSmartInstrumentIDRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.financialPaymentView.UpdateForSmartInstrumentIDResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.financialPaymentView.UpdateForSmartInstrumentIDResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.financialPaymentView.ModelUpdaterClient.prototype.updateForSmartInstrumentID =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/financialPaymentView.ModelUpdater/UpdateForSmartInstrumentID',
      request,
      metadata || {},
      methodDescriptor_ModelUpdater_UpdateForSmartInstrumentID,
      callback);
};


/**
 * @param {!proto.financialPaymentView.UpdateForSmartInstrumentIDRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.financialPaymentView.UpdateForSmartInstrumentIDResponse>}
 *     Promise that resolves to the response
 */
proto.financialPaymentView.ModelUpdaterPromiseClient.prototype.updateForSmartInstrumentID =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/financialPaymentView.ModelUpdater/UpdateForSmartInstrumentID',
      request,
      metadata || {},
      methodDescriptor_ModelUpdater_UpdateForSmartInstrumentID);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.financialPaymentView.UpdateForPaymentRequest,
 *   !proto.financialPaymentView.UpdateForPaymentResponse>}
 */
const methodDescriptor_ModelUpdater_UpdateForPayment = new grpc.web.MethodDescriptor(
  '/financialPaymentView.ModelUpdater/UpdateForPayment',
  grpc.web.MethodType.UNARY,
  proto.financialPaymentView.UpdateForPaymentRequest,
  proto.financialPaymentView.UpdateForPaymentResponse,
  /**
   * @param {!proto.financialPaymentView.UpdateForPaymentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.financialPaymentView.UpdateForPaymentResponse.deserializeBinary
);


/**
 * @param {!proto.financialPaymentView.UpdateForPaymentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.financialPaymentView.UpdateForPaymentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.financialPaymentView.UpdateForPaymentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.financialPaymentView.ModelUpdaterClient.prototype.updateForPayment =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/financialPaymentView.ModelUpdater/UpdateForPayment',
      request,
      metadata || {},
      methodDescriptor_ModelUpdater_UpdateForPayment,
      callback);
};


/**
 * @param {!proto.financialPaymentView.UpdateForPaymentRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.financialPaymentView.UpdateForPaymentResponse>}
 *     Promise that resolves to the response
 */
proto.financialPaymentView.ModelUpdaterPromiseClient.prototype.updateForPayment =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/financialPaymentView.ModelUpdater/UpdateForPayment',
      request,
      metadata || {},
      methodDescriptor_ModelUpdater_UpdateForPayment);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.financialPaymentView.FullUpdateRequest,
 *   !proto.financialPaymentView.FullUpdateResponse>}
 */
const methodDescriptor_ModelUpdater_FullUpdate = new grpc.web.MethodDescriptor(
  '/financialPaymentView.ModelUpdater/FullUpdate',
  grpc.web.MethodType.UNARY,
  proto.financialPaymentView.FullUpdateRequest,
  proto.financialPaymentView.FullUpdateResponse,
  /**
   * @param {!proto.financialPaymentView.FullUpdateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.financialPaymentView.FullUpdateResponse.deserializeBinary
);


/**
 * @param {!proto.financialPaymentView.FullUpdateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.financialPaymentView.FullUpdateResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.financialPaymentView.FullUpdateResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.financialPaymentView.ModelUpdaterClient.prototype.fullUpdate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/financialPaymentView.ModelUpdater/FullUpdate',
      request,
      metadata || {},
      methodDescriptor_ModelUpdater_FullUpdate,
      callback);
};


/**
 * @param {!proto.financialPaymentView.FullUpdateRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.financialPaymentView.FullUpdateResponse>}
 *     Promise that resolves to the response
 */
proto.financialPaymentView.ModelUpdaterPromiseClient.prototype.fullUpdate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/financialPaymentView.ModelUpdater/FullUpdate',
      request,
      metadata || {},
      methodDescriptor_ModelUpdater_FullUpdate);
};


module.exports = proto.financialPaymentView;

