/**
 * @fileoverview gRPC-Web generated client stub for financial
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.28.0
// source: financial/primeRateReader_meshproto.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var security_context_pb = require('../security/context_pb.js')

var search_criterion_pb = require('../search/criterion_pb.js')

var search_query_pb = require('../search/query_pb.js')

var search_sorting_pb = require('../search/sorting_pb.js')

var financial_primeRate_pb = require('../financial/primeRate_pb.js')

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var num_decimal_pb = require('../num/decimal_pb.js')
const proto = {};
proto.financial = require('./primeRateReader_meshproto_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.financial.PrimeRateReaderClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.financial.PrimeRateReaderPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.financial.ReadOnePrimeRateRequest,
 *   !proto.financial.ReadOnePrimeRateResponse>}
 */
const methodDescriptor_PrimeRateReader_ReadOnePrimeRate = new grpc.web.MethodDescriptor(
  '/financial.PrimeRateReader/ReadOnePrimeRate',
  grpc.web.MethodType.UNARY,
  proto.financial.ReadOnePrimeRateRequest,
  proto.financial.ReadOnePrimeRateResponse,
  /**
   * @param {!proto.financial.ReadOnePrimeRateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.financial.ReadOnePrimeRateResponse.deserializeBinary
);


/**
 * @param {!proto.financial.ReadOnePrimeRateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.financial.ReadOnePrimeRateResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.financial.ReadOnePrimeRateResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.financial.PrimeRateReaderClient.prototype.readOnePrimeRate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/financial.PrimeRateReader/ReadOnePrimeRate',
      request,
      metadata || {},
      methodDescriptor_PrimeRateReader_ReadOnePrimeRate,
      callback);
};


/**
 * @param {!proto.financial.ReadOnePrimeRateRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.financial.ReadOnePrimeRateResponse>}
 *     Promise that resolves to the response
 */
proto.financial.PrimeRateReaderPromiseClient.prototype.readOnePrimeRate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/financial.PrimeRateReader/ReadOnePrimeRate',
      request,
      metadata || {},
      methodDescriptor_PrimeRateReader_ReadOnePrimeRate);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.financial.ReadManyPrimeRateRequest,
 *   !proto.financial.ReadManyPrimeRateResponse>}
 */
const methodDescriptor_PrimeRateReader_ReadManyPrimeRate = new grpc.web.MethodDescriptor(
  '/financial.PrimeRateReader/ReadManyPrimeRate',
  grpc.web.MethodType.UNARY,
  proto.financial.ReadManyPrimeRateRequest,
  proto.financial.ReadManyPrimeRateResponse,
  /**
   * @param {!proto.financial.ReadManyPrimeRateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.financial.ReadManyPrimeRateResponse.deserializeBinary
);


/**
 * @param {!proto.financial.ReadManyPrimeRateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.financial.ReadManyPrimeRateResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.financial.ReadManyPrimeRateResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.financial.PrimeRateReaderClient.prototype.readManyPrimeRate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/financial.PrimeRateReader/ReadManyPrimeRate',
      request,
      metadata || {},
      methodDescriptor_PrimeRateReader_ReadManyPrimeRate,
      callback);
};


/**
 * @param {!proto.financial.ReadManyPrimeRateRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.financial.ReadManyPrimeRateResponse>}
 *     Promise that resolves to the response
 */
proto.financial.PrimeRateReaderPromiseClient.prototype.readManyPrimeRate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/financial.PrimeRateReader/ReadManyPrimeRate',
      request,
      metadata || {},
      methodDescriptor_PrimeRateReader_ReadManyPrimeRate);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.financial.ReadAllPrimeRateRequest,
 *   !proto.financial.ReadAllPrimeRateResponse>}
 */
const methodDescriptor_PrimeRateReader_ReadAllPrimeRate = new grpc.web.MethodDescriptor(
  '/financial.PrimeRateReader/ReadAllPrimeRate',
  grpc.web.MethodType.UNARY,
  proto.financial.ReadAllPrimeRateRequest,
  proto.financial.ReadAllPrimeRateResponse,
  /**
   * @param {!proto.financial.ReadAllPrimeRateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.financial.ReadAllPrimeRateResponse.deserializeBinary
);


/**
 * @param {!proto.financial.ReadAllPrimeRateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.financial.ReadAllPrimeRateResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.financial.ReadAllPrimeRateResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.financial.PrimeRateReaderClient.prototype.readAllPrimeRate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/financial.PrimeRateReader/ReadAllPrimeRate',
      request,
      metadata || {},
      methodDescriptor_PrimeRateReader_ReadAllPrimeRate,
      callback);
};


/**
 * @param {!proto.financial.ReadAllPrimeRateRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.financial.ReadAllPrimeRateResponse>}
 *     Promise that resolves to the response
 */
proto.financial.PrimeRateReaderPromiseClient.prototype.readAllPrimeRate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/financial.PrimeRateReader/ReadAllPrimeRate',
      request,
      metadata || {},
      methodDescriptor_PrimeRateReader_ReadAllPrimeRate);
};


module.exports = proto.financial;

