import { useEffect } from "react"; // Import useEffect hook from React.
import { hash } from "utilities/hash/hash"; // Import a hashing utility function.
import { userAffiliations, userCategories, userLoginTypes } from "const/gtm"; // Import constants for Google Tag Manager (GTM).
import { useGTMTriggersPusher } from "hooks/analytics/useGTMTriggersPusher"; // Import a custom hook for GTM event pushing.
import { useLocalStorage } from "hooks/persistentStateStore/useLocalStorage"; // Import a custom hook for local storage management.
import { User } from "james/user"; // Import the User model from a custom module.
import { Client, ClientKYCStatus } from "james/client";

// Define a custom hook to observe user login events and trigger GTM events.
export const useUserLoggedInGTMObserver = (
  userAuthenticated?: boolean, // Optional flag indicating if the user is authenticated.
  user?: User, // Optional User object.
  client?: Client, // Optional Client object.
) => {
  const { pushLoginCompletedEvent } = useGTMTriggersPusher(); // Get the function to push login events to GTM.
  const { updateStore, getStore } = useLocalStorage(); // Get functions to manage the local storage state.

  // Use the useEffect hook to perform side effects based on the user's authentication status.
  useEffect(() => {
    // Prevent multiple event triggers if the user is already logged in (e.g., when refreshing the browser).
    const store = getStore();
    if (store.loggedIn) {
      return;
    }

    // Exit early if user is not authenticated or if the user/client objects are not provided.
    if (!userAuthenticated || !user || !client) {
      return;
    }

    // Push a login completed event to GTM with relevant user and client data.
    pushLoginCompletedEvent({
      user_id: user.id, // User's ID.
      hashed_email: hash(user.email), // Hashed email for security.
      user_category: userCategories.active, // User's category.
      user_login_type: userLoginTypes.loggedIn, // User's login type.
      user_primary_affiliation: userAffiliations.internal, // User's primary affiliation.
      user_kyc_approved_date:
        client.kycStatus === ClientKYCStatus.VerifiedStatus
          ? client.kycVerificationDate
          : "", // user_kyc_approved date is only set if the client kyc status is verified.
      user_kyc_request_date: "", // This is left blank it's only set when the user start the kyc journey
      user_kyc_status: client.kycStatus, // Client's KYC status.
    });

    // Update the local storage state to reflect the user's logged-in status.
    updateStore((store) => {
      return {
        ...store,
        userID: user.id,
        loggedIn: true,
      };
    });
  }, [user, client, userAuthenticated, getStore().loggedIn]); // Dependency array for the useEffect hook.
};
