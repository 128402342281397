import { Box, Grid, Typography } from "@mui/material";
import dayjs from "dayjs";
import { MarketListingViewModel } from "james/views/marketListingView";
import { useEffect, useState } from "react";
import { SkeletonLoader } from "components/SkeletonLoader";
import { AssetInformation } from "../Context";

interface SecuritiesRegisterOverviewProps {
  marketListingViewModel: MarketListingViewModel;
  assetInformation: AssetInformation;
  loading: boolean;
}

export const SecuritiesRegisterOverview = ({
  marketListingViewModel,
  assetInformation,
  loading,
}: SecuritiesRegisterOverviewProps) => {
  const [data, setData] = useState<MarketListingViewModel>(
    marketListingViewModel ?? new MarketListingViewModel(),
  );
  const [asset, setAsset] = useState<AssetInformation>(assetInformation);

  useEffect(() => {
    if (
      marketListingViewModel === undefined ||
      assetInformation === undefined
    ) {
      return;
    }
    setData(marketListingViewModel);
    setAsset(assetInformation);
  }, [marketListingViewModel, assetInformation]);

  return (
    <Box mb={5}>
      <Typography variant="h1" fontWeight={600} mb={2}>
        Securities Register
      </Typography>
      <Typography variant="body1" mb={3} sx={{ fontStyle: "italic" }}>
        "A list of noteholders for certificated securities whose transfer has
        been restricted"
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={4}>
          <Typography variant="subtitle2">Issuer</Typography>
          <SkeletonLoader loading={loading}>
            <Typography variant="h6">{asset.issuerName}</Typography>
          </SkeletonLoader>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle2">Instrument Name</Typography>
          <SkeletonLoader loading={loading}>
            <Typography variant="h6">{asset.assetName}</Typography>
          </SkeletonLoader>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle2">Tokens in Circulation</Typography>
          <SkeletonLoader loading={loading}>
            <Typography variant="h6">
              {asset.assetIssuedUnits}
              {" Units"}
            </Typography>
          </SkeletonLoader>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle2">State of Instrument</Typography>
          <SkeletonLoader loading={loading}>
            <Typography variant="h6">{asset.assetState}</Typography>
          </SkeletonLoader>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle2">Issue Date</Typography>
          <SkeletonLoader loading={loading}>
            <Typography variant="h6">
              {dayjs(data?.assetIssueDate).format("YYYY/MM/DD")}
            </Typography>
          </SkeletonLoader>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle2">Maturity Date</Typography>
          <Typography variant="h6">{asset.assetMaturityDate}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle2">No "uncertificated" note</Typography>
          <SkeletonLoader loading={loading}>
            <Typography variant="h6">
              {"0 (Zero) notes are held in uncertificated form"}
            </Typography>
          </SkeletonLoader>
        </Grid>
      </Grid>
    </Box>
  );
};
