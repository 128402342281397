import { Token } from "james/ledger";
import { StateStore } from "./type"; // Import the StateStore type from the 'type' file.
import { useCallback } from "react"; // Import the useCallback hook from React.
import { determineEnvironment } from "utilities/general"; // Import a utility function to determine the current environment.

// Define a type for the local storage store with three functions.
export type localStorageStore = {
  getStore: () => StateStore; // Function to get the store's current state.
  updateStore: (
    store: StateStore | ((currentStore: StateStore) => StateStore),
  ) => void; // Update the store, either with a new state or a callback.
  clearStore: () => void; // Function to clear the store's state.
};

// Define a hook to use local storage for state management.
export const useLocalStorage = (): localStorageStore => {
  // Function to get the current state of the store from local storage.
  const getStore = useCallback((): StateStore => {
    const env = determineEnvironment(); // Determine the current environment.

    // Retrieve the serialized store data from local storage.
    const store = localStorage.getItem(`${env.toLowerCase()}/meshtrade-store`);

    // If the store is not defined, return an initial store state.
    if (!store) {
      return {
        userID: "",
        loggedIn: false,
        individualRegistrationInProgress: false,
        companyRegistrationInProgress: false,
        redirectURL: "",
        redirectAssetName: "",
        redirectAssetShortName: "",
        redirectToken: new Token(),
        testURLParamConfig: {
          sessionExpiry: undefined,
        },
      } as StateStore;
    }
    // Parse the stored JSON string back into a StateStore object.
    return JSON.parse(store) as StateStore;
  }, []);

  // Function to update the store, either with a new state or a callback.
  const updateStore = useCallback(
    (store: StateStore | ((currentStore: StateStore) => StateStore)) => {
      const currentStore = getStore(); // Get the current state of the store.

      // If 'store' is a function, call it with the current store to get the new store state.
      const updatedStore =
        typeof store === "function"
          ? store(currentStore)
          : { ...currentStore, ...store };

      // Serialize and persist the updated store state in local storage.
      const env = determineEnvironment();
      localStorage.setItem(
        `${env.toLowerCase()}/meshtrade-store`,
        JSON.stringify(updatedStore as StateStore),
      );
    },
    [getStore],
  );

  // Function to clear the store's state in local storage.
  const clearStore = useCallback(() => {
    const env = determineEnvironment(); // Determine the current environment.

    // Reset the store to its initial state and serialize it to a JSON string.
    localStorage.setItem(
      `${env.toLowerCase()}/meshtrade-store`,
      JSON.stringify({
        userID: "",
        loggedIn: false,
        individualRegistrationInProgress: false,
        companyRegistrationInProgress: false,
        redirectURL: "",
        redirectAssetName: "",
        redirectAssetShortName: "",
        redirectToken: new Token(),
        testURLParamConfig: {
          sessionExpiry: undefined,
        },
      } as StateStore),
    );
  }, []);

  // Return the local storage store with the defined functions.
  return {
    getStore,
    updateStore,
    clearStore,
  } as localStorageStore;
};
