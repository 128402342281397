/**
 * @fileoverview gRPC-Web generated client stub for financial
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.28.0
// source: financial/smartInstrumentUpdater.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var financial_smartInstrument_pb = require('../financial/smartInstrument_pb.js')

var document_document_pb = require('../document/document_pb.js')

var security_context_pb = require('../security/context_pb.js')
const proto = {};
proto.financial = require('./smartInstrumentUpdater_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.financial.SmartInstrumentUpdaterClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.financial.SmartInstrumentUpdaterPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.financial.DraftUpdateSmartInstrumentRequest,
 *   !proto.financial.DraftUpdateSmartInstrumentResponse>}
 */
const methodDescriptor_SmartInstrumentUpdater_DraftUpdateSmartInstrument = new grpc.web.MethodDescriptor(
  '/financial.SmartInstrumentUpdater/DraftUpdateSmartInstrument',
  grpc.web.MethodType.UNARY,
  proto.financial.DraftUpdateSmartInstrumentRequest,
  proto.financial.DraftUpdateSmartInstrumentResponse,
  /**
   * @param {!proto.financial.DraftUpdateSmartInstrumentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.financial.DraftUpdateSmartInstrumentResponse.deserializeBinary
);


/**
 * @param {!proto.financial.DraftUpdateSmartInstrumentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.financial.DraftUpdateSmartInstrumentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.financial.DraftUpdateSmartInstrumentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.financial.SmartInstrumentUpdaterClient.prototype.draftUpdateSmartInstrument =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/financial.SmartInstrumentUpdater/DraftUpdateSmartInstrument',
      request,
      metadata || {},
      methodDescriptor_SmartInstrumentUpdater_DraftUpdateSmartInstrument,
      callback);
};


/**
 * @param {!proto.financial.DraftUpdateSmartInstrumentRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.financial.DraftUpdateSmartInstrumentResponse>}
 *     Promise that resolves to the response
 */
proto.financial.SmartInstrumentUpdaterPromiseClient.prototype.draftUpdateSmartInstrument =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/financial.SmartInstrumentUpdater/DraftUpdateSmartInstrument',
      request,
      metadata || {},
      methodDescriptor_SmartInstrumentUpdater_DraftUpdateSmartInstrument);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.financial.ChangeSmartInstrumentDocumentsRequest,
 *   !proto.financial.ChangeSmartInstrumentDocumentsResponse>}
 */
const methodDescriptor_SmartInstrumentUpdater_ChangeSmartInstrumentDocuments = new grpc.web.MethodDescriptor(
  '/financial.SmartInstrumentUpdater/ChangeSmartInstrumentDocuments',
  grpc.web.MethodType.UNARY,
  proto.financial.ChangeSmartInstrumentDocumentsRequest,
  proto.financial.ChangeSmartInstrumentDocumentsResponse,
  /**
   * @param {!proto.financial.ChangeSmartInstrumentDocumentsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.financial.ChangeSmartInstrumentDocumentsResponse.deserializeBinary
);


/**
 * @param {!proto.financial.ChangeSmartInstrumentDocumentsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.financial.ChangeSmartInstrumentDocumentsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.financial.ChangeSmartInstrumentDocumentsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.financial.SmartInstrumentUpdaterClient.prototype.changeSmartInstrumentDocuments =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/financial.SmartInstrumentUpdater/ChangeSmartInstrumentDocuments',
      request,
      metadata || {},
      methodDescriptor_SmartInstrumentUpdater_ChangeSmartInstrumentDocuments,
      callback);
};


/**
 * @param {!proto.financial.ChangeSmartInstrumentDocumentsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.financial.ChangeSmartInstrumentDocumentsResponse>}
 *     Promise that resolves to the response
 */
proto.financial.SmartInstrumentUpdaterPromiseClient.prototype.changeSmartInstrumentDocuments =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/financial.SmartInstrumentUpdater/ChangeSmartInstrumentDocuments',
      request,
      metadata || {},
      methodDescriptor_SmartInstrumentUpdater_ChangeSmartInstrumentDocuments);
};


module.exports = proto.financial;

