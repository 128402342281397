import { Box } from "@mui/material";
import React from "react";
import meshMiniLogo from "assets/images/logo/meshLogo.svg";
import { SignInCard } from "views/SignIn/SignInCard";
import { isWidthUp } from "@mui/material/Hidden/withWidth";
import { useWidth } from "utilities/general";
import { Helmet } from "react-helmet-async";
import { useAppNoticeContext } from "context/AppNotice/AppNotice";
import background from "assets/images/background/background.png";
import { useTestURLQueryParamsHandler } from "../../hooks/useTestURLQueryParamsHandler";

export function SignIn() {
  const width = useWidth();
  const { Banner } = useAppNoticeContext();
  useTestURLQueryParamsHandler();

  return (
    <>
      <Helmet>
        <title>Mesh | Sign in</title>
        <meta charSet="utf-8" />
        <meta name="description" content="Sign in to your Mesh account" />
      </Helmet>
      {Banner}
      {isWidthUp("sm", width) ? (
        <Box
          sx={{
            display: "grid",
            backgroundImage: `url(${background})`,
            gridTemplateRows: "auto 1fr",
            backgroundSize: "cover",
            height: "100vh",
            overflowY: isWidthUp("md", width) ? "hidden" : "auto",
            width: "100%",
          }}
        >
          <Box
            sx={{
              width: 168,
              height: 77,
            }}
          >
            <img alt="" src={meshMiniLogo} />
          </Box>
          <Box
            sx={{
              minWidth: "100vw",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              paddingBottom: "24px",
            }}
          >
            <SignInCard />
          </Box>
        </Box>
      ) : (
        <>
          <SignInCard />
        </>
      )}
    </>
  );
}
