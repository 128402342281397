import {
  useMediaQuery,
  DialogContent,
  DialogTitle,
  DialogProps,
  Typography,
  IconButton,
  Dialog,
  Button,
  Theme,
  Box,
  Link,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import BackgroundImage from "assets/images/welomeDialog/welcomeDialogBackground.jpg";

import MobileBackgroundImage from "assets/images/welomeDialog/welcomeDialogBackgroundMobile.jpg";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "hooks/persistentStateStore/useLocalStorage";
import { Token } from "james/ledger";

export interface AssetOverviewWelcomeDialogProps {
  dialogProps: DialogProps;
  closeDialog: () => void;
  assetName: string;
  assetShortName: string;
  assetToken: Token;
}

export const AssetOverviewWelcomeDialog = ({
  dialogProps,
  closeDialog,
  assetName,
  assetShortName,
  assetToken,
}: AssetOverviewWelcomeDialogProps) => {
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const { updateStore } = useLocalStorage();
  return (
    <Dialog
      {...dialogProps}
      PaperProps={{
        ...dialogProps.PaperProps,
      }}
      slotProps={{}}
      fullScreen={smDown}
    >
      <AssetOverviewWelcomeDialogTitle closeDialog={closeDialog} />
      <DialogContent sx={{ p: { sm: 3, xs: 3 }, pb: 5 }}>
        <Typography
          sx={(theme) => ({
            mt: 3,
            fontSize: "14px",
            lineHeight: "19px",
            color: theme.palette.text.secondary,
          })}
          variant="body2"
        >
          Mesh is an ecosystem for raising capital and investing in smart
          financial assets. Mesh powers open capital markets that are easy
          to&nbsp;access, simple to use and transparent. Join Mesh to access
          this and other interesting alternative and private capital assets,
          available only to registered Mesh Investors.
        </Typography>
        <Box
          sx={{
            mt: 4,
            display: "flex",
            flexDirection: { sm: "row", xs: "column" },
            alignItems: { sm: "center", xs: "flex-start" },
            justifyContent: "space-between",
            width: "100%",
            gap: 2,
          }}
        >
          <Link target="_blank" href="/sign-in" underline="none">
            <Typography sx={{ fontWeight: { sm: "normal", xs: "bold" } }}>
              Sign In To Mesh
            </Typography>
          </Link>
          {!smDown && (
            <Box
              sx={{
                display: "flex",
                gap: 2,
                width: { smDown: "100%" },
              }}
            >
              <Button
                sx={{
                  height: {
                    sm: 36,
                    xs: 48,
                  },
                }}
                variant={"outlined"}
                children={"Sign Up on Mesh"}
                onClick={() => {
                  updateStore((store) => {
                    return {
                      ...store,
                      redirectURL: window.location.href.split("/public")[1],
                      redirectAssetName: assetName,
                      redirectAssetShortName: assetShortName,
                      redirectToken: assetToken,
                    };
                  });
                  navigate("/sign-up");
                }}
              />
              <Button
                sx={{
                  height: {
                    sm: 36,
                    xs: 48,
                  },
                }}
                variant={"contained"}
                color={"primary"}
                children={"View Asset"}
                onClick={closeDialog}
              />
            </Box>
          )}
        </Box>
      </DialogContent>
      {smDown && (
        <DialogTitle
          sx={(theme) => ({
            padding: theme.spacing(3, 3, 5, 3),
            backgroundColor: theme.palette.custom.cardInner,
          })}
        >
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gap: 2,
            }}
          >
            <Button
              sx={{
                height: 48,
              }}
              variant={"outlined"}
              children={"Sign Up"}
              onClick={() => {
                updateStore((store) => {
                  return {
                    ...store,
                    redirectURL: window.location.href.split("/public")[1],
                    redirectAssetName: assetName,
                    redirectAssetShortName: assetShortName,
                    redirectToken: assetToken,
                  };
                });
                navigate("/sign-up");
              }}
            />
            <Button
              sx={{
                height: 48,
              }}
              variant={"contained"}
              color={"primary"}
              children={"View Asset"}
              onClick={closeDialog}
            />
          </Box>
        </DialogTitle>
      )}
    </Dialog>
  );
};

interface AssetOverviewWelcomeDialogTitleProps {
  closeDialog: () => void;
}

const AssetOverviewWelcomeDialogTitle = ({
  closeDialog,
}: AssetOverviewWelcomeDialogTitleProps) => {
  return (
    <DialogTitle
      sx={{
        height: 200,
        boxShadow: {
          sm: 0,
          xs: 2,
        },
        backgroundImage: {
          sm: `url(${BackgroundImage})`,
          xs: `url(${MobileBackgroundImage})`,
        }, // Set the background image
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      <Box
        sx={{
          height: 52,
          display: "flex",
          pl: "55%",
          flexDirection: "column",
        }}
      >
        <Typography variant="h4" children={"Welcome to"} />
        <Typography
          sx={{ fontSize: "48px", fontWeight: "bold", lineHeight: "56px" }}
          variant="h2"
          children={"Mesh"}
          color={"primary"}
        />
      </Box>
      <IconButton
        sx={{
          width: 14, // Adjust width as needed
          height: 14, // Adjust height as needed
          alignSelf: `flex-start`,
          justifySelf: "flex-end",
          p: 2,
        }}
        disabled={false}
        onClick={() => {
          closeDialog();
        }}
      >
        <CloseIcon />
      </IconButton>
    </DialogTitle>
  );
};
