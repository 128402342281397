import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Typography,
} from "@mui/material";
import { SecuritiesRegisterOverview } from "./components/SecuritiesRegisterOverview";
import { SecuritiesRegisterTable } from "./components/SecuritiesRegisterTable";
import { useSecuritiesRegisterContext } from "./Context";

export const Layout = () => {
  const {
    apiCallInProgress,
    initialised,
    initialisationError,
    clearInitialisationError,
    marketListingViewModel,
    assetInformation,
    assetHolderViewModels,
  } = useSecuritiesRegisterContext();

  if (initialisationError) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Card>
          <CardHeader title={"Error Initialising Securites Register"} />
          <CardContent
            sx={(theme) => ({
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyItems: "center",
              gap: theme.spacing(2),
            })}
          >
            <Typography>
              Something went wrong while initialising the securities register.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={clearInitialisationError}
            >
              Try Again
            </Button>
          </CardContent>
        </Card>
      </Box>
    );
  }

  const isAnyDataUndefined =
    marketListingViewModel === undefined ||
    assetInformation === undefined ||
    assetHolderViewModels === undefined;

  if (isAnyDataUndefined) {
    return <div>Loading...</div>;
  }

  return (
    <Box>
      <SecuritiesRegisterOverview
        marketListingViewModel={marketListingViewModel}
        assetInformation={assetInformation}
        loading={apiCallInProgress || !initialised}
      />
      <SecuritiesRegisterTable assetHolders={assetHolderViewModels} />
    </Box>
  );
};
