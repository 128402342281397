import {
  CryptoCurrency,
  CryptoCurrencyTypeName,
  CurrencyStablecoin,
  CurrencyStablecoinTypeName,
  YieldBearingStablecoin,
  YieldBearingStablecoinTypeName,
  DigitalETF,
  DigitalETFTypeName,
  DigitalETN,
  DigitalETNTypeName,
  ETFStablecoin,
  ETFStablecoinTypeName,
  ETNStablecoin,
  ETNStablecoinTypeName,
  UnitTrustStablecoin,
  UnitTrustStablecoinTypeName,
  DigitalETFState,
  DigitalETNState,
  ETFStablecoinState,
  ETNStablecoinState,
  UnitTrustStablecoinState,
} from "james/financial";
import { Token } from "./Token";

export interface Asset {
  ["@type"]: string;

  assetID(): string;

  assetOwnerID(): string;

  assetToken(): Token;

  assetIssueDate(): string;

  assetName(): string;

  assetShortName(): string;

  assetFractionalisationAllowed(): boolean;

  state?:
    | ""
    | DigitalETFState
    | DigitalETNState
    | ETFStablecoinState
    | ETNStablecoinState
    | UnitTrustStablecoinState;

  maturityDate?: string;
}

export function NewAsset(i: Asset): Asset {
  switch (i["@type"]) {
    case CryptoCurrencyTypeName:
      return new CryptoCurrency(i as CryptoCurrency);

    case CurrencyStablecoinTypeName:
      return new CurrencyStablecoin(i as CurrencyStablecoin);

    case YieldBearingStablecoinTypeName:
      return new YieldBearingStablecoin(i as YieldBearingStablecoin);

    case DigitalETFTypeName:
      return new DigitalETF(i as DigitalETF);

    case ETFStablecoinTypeName:
      return new ETFStablecoin(i as ETFStablecoin);

    case DigitalETNTypeName:
      return new DigitalETN(i as DigitalETN);

    case ETNStablecoinTypeName:
      return new ETNStablecoin(i as ETNStablecoin);

    case UnitTrustStablecoinTypeName:
      return new UnitTrustStablecoin(i as UnitTrustStablecoin);

    default:
      throw new TypeError(`unknown asset type ${i["@type"]}`);
  }
}
