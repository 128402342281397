/**
 * @fileoverview gRPC-Web generated client stub for financial
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.28.0
// source: financial/rateResetReader_meshproto.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var security_context_pb = require('../security/context_pb.js')

var search_criterion_pb = require('../search/criterion_pb.js')

var search_query_pb = require('../search/query_pb.js')

var search_sorting_pb = require('../search/sorting_pb.js')

var financial_rateReset_pb = require('../financial/rateReset_pb.js')

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var num_decimal_pb = require('../num/decimal_pb.js')
const proto = {};
proto.financial = require('./rateResetReader_meshproto_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.financial.RateResetReaderClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.financial.RateResetReaderPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.financial.ReadOneRateResetRequest,
 *   !proto.financial.ReadOneRateResetResponse>}
 */
const methodDescriptor_RateResetReader_ReadOneRateReset = new grpc.web.MethodDescriptor(
  '/financial.RateResetReader/ReadOneRateReset',
  grpc.web.MethodType.UNARY,
  proto.financial.ReadOneRateResetRequest,
  proto.financial.ReadOneRateResetResponse,
  /**
   * @param {!proto.financial.ReadOneRateResetRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.financial.ReadOneRateResetResponse.deserializeBinary
);


/**
 * @param {!proto.financial.ReadOneRateResetRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.financial.ReadOneRateResetResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.financial.ReadOneRateResetResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.financial.RateResetReaderClient.prototype.readOneRateReset =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/financial.RateResetReader/ReadOneRateReset',
      request,
      metadata || {},
      methodDescriptor_RateResetReader_ReadOneRateReset,
      callback);
};


/**
 * @param {!proto.financial.ReadOneRateResetRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.financial.ReadOneRateResetResponse>}
 *     Promise that resolves to the response
 */
proto.financial.RateResetReaderPromiseClient.prototype.readOneRateReset =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/financial.RateResetReader/ReadOneRateReset',
      request,
      metadata || {},
      methodDescriptor_RateResetReader_ReadOneRateReset);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.financial.ReadManyRateResetRequest,
 *   !proto.financial.ReadManyRateResetResponse>}
 */
const methodDescriptor_RateResetReader_ReadManyRateReset = new grpc.web.MethodDescriptor(
  '/financial.RateResetReader/ReadManyRateReset',
  grpc.web.MethodType.UNARY,
  proto.financial.ReadManyRateResetRequest,
  proto.financial.ReadManyRateResetResponse,
  /**
   * @param {!proto.financial.ReadManyRateResetRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.financial.ReadManyRateResetResponse.deserializeBinary
);


/**
 * @param {!proto.financial.ReadManyRateResetRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.financial.ReadManyRateResetResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.financial.ReadManyRateResetResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.financial.RateResetReaderClient.prototype.readManyRateReset =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/financial.RateResetReader/ReadManyRateReset',
      request,
      metadata || {},
      methodDescriptor_RateResetReader_ReadManyRateReset,
      callback);
};


/**
 * @param {!proto.financial.ReadManyRateResetRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.financial.ReadManyRateResetResponse>}
 *     Promise that resolves to the response
 */
proto.financial.RateResetReaderPromiseClient.prototype.readManyRateReset =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/financial.RateResetReader/ReadManyRateReset',
      request,
      metadata || {},
      methodDescriptor_RateResetReader_ReadManyRateReset);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.financial.ReadAllRateResetRequest,
 *   !proto.financial.ReadAllRateResetResponse>}
 */
const methodDescriptor_RateResetReader_ReadAllRateReset = new grpc.web.MethodDescriptor(
  '/financial.RateResetReader/ReadAllRateReset',
  grpc.web.MethodType.UNARY,
  proto.financial.ReadAllRateResetRequest,
  proto.financial.ReadAllRateResetResponse,
  /**
   * @param {!proto.financial.ReadAllRateResetRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.financial.ReadAllRateResetResponse.deserializeBinary
);


/**
 * @param {!proto.financial.ReadAllRateResetRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.financial.ReadAllRateResetResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.financial.ReadAllRateResetResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.financial.RateResetReaderClient.prototype.readAllRateReset =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/financial.RateResetReader/ReadAllRateReset',
      request,
      metadata || {},
      methodDescriptor_RateResetReader_ReadAllRateReset,
      callback);
};


/**
 * @param {!proto.financial.ReadAllRateResetRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.financial.ReadAllRateResetResponse>}
 *     Promise that resolves to the response
 */
proto.financial.RateResetReaderPromiseClient.prototype.readAllRateReset =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/financial.RateResetReader/ReadAllRateReset',
      request,
      metadata || {},
      methodDescriptor_RateResetReader_ReadAllRateReset);
};


module.exports = proto.financial;

