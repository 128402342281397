import { Breakpoint, useMediaQuery, useTheme } from "@mui/material";
import { useLayoutEffect, useState } from "react";
import { Environment } from "../../const";
import { StateStore } from "../../hooks/persistentStateStore/type";

/**
 * Allows the simulation of a sleep by
 * returns a promise that blocks for ms milliseconds
 * @param ms {number}
 */
export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

/**
 * @deprecated use useMediaQuery instead.
 * @docs https://mui.com/material-ui/react-use-media-query/
 * @example useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
 * @example useMediaQuery((theme: Theme) => theme.breakpoints.between('sm', 'xs'));
 */
export function useWidth() {
  const theme = useTheme();
  const keys = [...theme.breakpoints.keys].reverse();
  return (
    keys.reduce((output: Breakpoint | null, key: Breakpoint) => {
      const matches = useMediaQuery(theme.breakpoints.up(key));
      return !output && matches ? key : output;
    }, null) || "xs"
  );
}

/**
 * @returns [Horizontal Width, Vertical Width]
 */
export function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }

    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

export function determineEnvironment(): Environment {
  // Get the hostname from window.location
  const hostname = window.location.hostname;

  // Split the hostname by '.' to get the subdomains
  const subdomains = hostname.split(".");

  // Check the first subdomain to determine the environment
  const firstSubdomain = subdomains[0];

  // Map subdomains to environments
  switch (firstSubdomain) {
    case "app":
      return Environment.Production;
    case "development":
      return Environment.Development;
    case "testing":
      return Environment.Testing;
    case "staging":
      return Environment.Staging;
    case "localhost":
      return Environment.Local;
    default:
      return Environment.Local;
  }
}

export function SetTestConfigURLQueryParams(url: string): string {
  const env = determineEnvironment();
  let params = "";

  // Parse the stored JSON string back into a StateStore object.
  const store = JSON.parse(
    localStorage.getItem(`${env.toLowerCase()}/meshtrade-store`) as string,
  ) as StateStore;

  if (store.testURLParamConfig) {
    params += "?test=true";
  }

  // if the session expiry value is set on the local storage
  if (store.testURLParamConfig && store.testURLParamConfig.sessionExpiry) {
    params += `&loginSessionExpiry=${store.testURLParamConfig.sessionExpiry}`;
  }

  return url + params;
}
