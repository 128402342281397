import { jsonRPCRequest } from "utilities/network/jsonRPCRequest";
import config from "react-global-configuration";
import { AssetParticipants } from "./AssetParticipants";
import { Token } from "./Token";
import { Amount } from "./Amount";

export const AssetInspectorServiceProviderName = "ledger-AssetInspector";

interface GetAssetParticipantsRequest {
  token: Token;
}

interface GetAssetParticipantsResponse {
  participants: AssetParticipants;
}

interface GetAssetTokenIssuerNameRequest {
  token: Token;
}

interface GetAssetTokenIssuerNameResponse {
  name: string;
}

interface GetTotalIssuedUnitsByTokenRequest {
  token: Token;
}

interface GetTotalIssuedUnitsByTokenResponse {
  amount: Amount;
}

export const AssetInspector = {
  serviceProviderName: AssetInspectorServiceProviderName,
  async GetAssetParticipants(
    request: GetAssetParticipantsRequest,
  ): Promise<GetAssetParticipantsResponse> {
    const response = await jsonRPCRequest({
      url: config.get("meshPublicURL"),
      method: `${AssetInspectorServiceProviderName}.GetAssetParticipants`,
      request,
    });

    return {
      participants: new AssetParticipants(response.participants),
    };
  },

  async GetAssetTokenIssuerName(
    request: GetAssetTokenIssuerNameRequest,
  ): Promise<GetAssetTokenIssuerNameResponse> {
    const response = await jsonRPCRequest({
      url: config.get("meshPublicURL"),
      method: `${AssetInspectorServiceProviderName}.GetAssetTokenIssuerName`,
      request,
    });

    return {
      name: response.name,
    };
  },

  async GetTotalIssuedUnitsByToken(
    request: GetTotalIssuedUnitsByTokenRequest,
  ): Promise<GetTotalIssuedUnitsByTokenResponse> {
    const response = await jsonRPCRequest({
      url: config.get("meshPublicURL"),
      method: `${AssetInspectorServiceProviderName}.GetTotalIssuedUnitsByToken`,
      request,
    });

    return {
      amount: new Amount(response.amount),
    };
  },
};
